import {
  CutlistState,
  Edgebanding,
  EdgeProfile,
} from '@cutr/constants/cutlist';
import { NestingOutput, Sheet } from '@cutr/constants/cutlist-nesting';
import { SheetEdgeTrimConfig } from '@cutr/constants/cutlist-theme';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useLeadDetails } from '@/api/account';
import { api } from '@/api/backend';
import { useNestingErrorStore, usePartsErrorStore } from '@/api/errors';
import { useAgentLoggedIn, useAuthStore } from '@/api/login';
import { getMaterial } from '@/api/materials';
import {
  useActiveGroupGrainDirection,
  useActiveGroupPricingSensitiveData,
  useMaterialGroups,
  useMaterialGroupState,
  useSaveSensitiveData,
  useSheetEdgeTrimConfig,
} from '@/api/materialsGroup';
import {
  SheetGroup,
  useNestingStatusStore,
  useNestingStore,
} from '@/api/nesting';
import { usePricingStore } from '@/api/pricing';
import {
  useCutlistParts,
  useCutlistState,
  useSavingDraftStore,
} from '@/api/store';
import Card from '@/blocks/Card';
import { Pagination } from '@/blocks/Pagination';
import { CutlistNesting } from '@/nesting/nesting';
import { useGetPriceForMaterialGroups } from '@/queries/agent';
import { useUpdateCutlist } from '@/queries/crud';
import { useCurrentFeatures, useThemeConfig } from '@/theme';
import { useDebounce } from '@/utils/hooks';
import { isInIframe, isTruthy } from '@/utils/misc';
import {
  convertGroupToInput,
  createBackendGrouping,
  groupPartsForNesting,
  NestingGroup,
  Scheduler,
} from '@/utils/nesting';

import styles from './Nesting.module.css';
const ENVIRONMENT = import.meta.env.VITE_CUTR_ENV;
const selectHasMaterials = (state: CutlistState) => state.hasMaterials;

export const useDebouncedParts = () => {
  const parts = useCutlistParts();
  const debouncedParts = useDebounce(parts, 500);

  return [parts, debouncedParts];
};

let ref = '';
export function useRunNesting() {
  const { t } = useTranslation();

  const { addError: addNestingError, resetErrors } = useNestingErrorStore();
  const { nestingConfig } = useThemeConfig();
  const { addPartError, resetPartsNestingError } = usePartsErrorStore();
  const setNesting = useNestingStore((s) => s.setNesting);
  const hasMaterials = useCutlistState(selectHasMaterials);
  const [parts, debouncedParts] = useDebouncedParts();
  const groups = useMaterialGroups();
  const sheetEdgeTrimConfig = useSheetEdgeTrimConfig();
  const { setIsSaving } = useSavingDraftStore();
  // hack, make sure nesting does not trigger if parts did not change
  ref = JSON.stringify(debouncedParts);

  const nestingGroupRef = React.useRef<Record<string, NestingGroup>>({});
  const nestingOutputRef = React.useRef<Record<string, NestingOutput>>({});

  const runNesting = React.useCallback(() => {
    resetPartsNestingError();
    resetErrors();
    nestingGroupRef.current = groupPartsForNesting(parts, groups);

    if (!Object.keys(nestingGroupRef.current).length) {
      nestingOutputRef.current = {};
      updateNesting();
      setIsSaving(false);
      return;
    }

    const scheduler = Scheduler(CutlistNesting, nestingConfig);
    scheduler.onUpdate((id, output) => {
      nestingOutputRef.current[id] = output;
      updateNesting();
    });
    scheduler.onError((message, data) => {
      if (!data) {
        addNestingError(message);
        return;
      }

      message = t('cutlist-summary.nesting.partFitError', {
        name: data.name,
        partSize: [data.partLengthMM, data.partWidthMM].join('x'),
        sheetSize: [data.sheetLengthMM, data.sheetWidthMM].join('x'),
      });

      if (data.hint)
        message +=
          ' ' + t(`cutlist-summary.nesting.partFitErrorHints.${data.hint}`);

      addPartError(data?.id, 'nesting', message);
    });

    Object.keys(nestingGroupRef.current).forEach((id) => {
      const nestingGroup = nestingGroupRef.current[id];

      if (!getMaterial(nestingGroup.materialGroup.core1)) return;

      useNestingStatusStore.getState().setStatus(id, 'running');

      scheduler.add(id, convertGroupToInput(nestingGroup));
    });

    setIsSaving(true);
    scheduler.run();
    updateNesting();

    return scheduler.abort;
  }, [ref, groups]);

  React.useEffect(runNesting, [ref, hasMaterials, sheetEdgeTrimConfig]);

  const updateNesting = () => {
    const sheetGroups = Object.keys(nestingOutputRef.current)
      .map((id) => {
        const nestingOutput = nestingOutputRef.current[id];
        const nestingGroup = nestingGroupRef.current[id];

        if (!nestingGroup || !nestingGroup.materialGroup) return;

        return { ...nestingOutput, ...nestingGroup } as SheetGroup;
      })
      .filter(isTruthy);
    setNesting(sheetGroups);
  };
}

export const Nesting = () => {
  return (
    <>
      <NestingHooks />
      <NestingGraph />
    </>
  );
};

const NestingHooks = () => {
  useRunNesting();
  usePricingFromNesting();
  return null;
};

const useActiveSheetGroups = () => {
  const sheetGroups = useNestingStore((state) => state.sheetGroups);
  const activeTab = useMaterialGroupState((state) => state.activeGroup);
  return sheetGroups.filter((group) =>
    group.parts.find((p) => !activeTab || p.groupId === activeTab)
  );
};

export const NestingGraph = () => {
  const sheetGroups = useActiveSheetGroups();
  const { t } = useTranslation();

  return (
    <Card>
      <h3>{t('cutlist-summary.nesting.title')}</h3>
      <p />

      <div>
        {sheetGroups.map((group, id) => {
          const thickness = group.sheetConfig.thicknessUM / 1000 + 'mm';
          const size: [number, number] = [
            group.sheetConfig.lengthMM,
            group.sheetConfig.widthMM,
          ];
          const sheetDimensions =
            group.materialGroup.sheetSizeSelection === 'automatic'
              ? group.materialGroup.automaticSheetSizeMaterials.map((code) => {
                  const material = getMaterial(code);
                  if (!material) return {};
                  const isSelected = group.materialGroup.core1 === code;
                  const dimensionString = `${material.lengthMM} x ${material.widthMM}`;
                  return {
                    dimensionString: isSelected
                      ? `${dimensionString} (${t(
                          `cutlist-summary.nesting.sheetCount`,
                          {
                            count: group.sheets.length,
                          }
                        )})`
                      : dimensionString,
                    isSelected,
                  };
                })
              : [
                  {
                    dimensionString: `${group.sheetConfig.lengthMM} x ${group.sheetConfig.widthMM}`,
                    isSelected: true,
                  },
                ];

          return (
            <div key={id}>
              <div id={group.materialGroup.id}>
                <div className="opposites">
                  <div className={styles.info}>
                    <label>
                      {t(`cutlist-summary.nesting.partInfo.thickness`)}
                    </label>
                    <div>{thickness}</div>
                  </div>
                  {ENVIRONMENT !== 'production' && (
                    <div className={styles.info}>
                      <label>{'Margin'}</label>
                      <div>
                        {`↓: ${group.sheetConfig.sheetEdgeTrimConfig.trimThickness.length1TrimThicknessMM}
                      ↑: ${group.sheetConfig.sheetEdgeTrimConfig.trimThickness.length2TrimThicknessMM}
                      ←: ${group.sheetConfig.sheetEdgeTrimConfig.trimThickness.width1TrimThicknessMM}
                      →: ${group.sheetConfig.sheetEdgeTrimConfig.trimThickness.width2TrimThicknessMM}`}
                      </div>
                    </div>
                  )}
                  <div className={styles.info}>
                    <label>{t(`cutlist-summary.nesting.partInfo.size`)}</label>
                    {sheetDimensions.map(({ dimensionString, isSelected }) => (
                      <div
                        key={dimensionString}
                        className={cn(
                          styles.info,
                          isSelected
                            ? styles.selectedDimension
                            : styles.notSelectedDimension
                        )}
                      >
                        {dimensionString}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <Pagination navKey="sheet">
                {group.sheets.map((sheet, idx) => (
                  <div key={idx}>
                    <NestingViz
                      size={size}
                      sheet={sheet}
                      sheetEdgeTrimConfig={
                        group.sheetConfig.sheetEdgeTrimConfig
                      }
                    />
                  </div>
                ))}
              </Pagination>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

const NestingViz = ({
  size,
  sheet,
  sheetEdgeTrimConfig,
}: {
  sheet: Sheet;
  size: number[];
  sheetEdgeTrimConfig: SheetEdgeTrimConfig | null;
}) => {
  const [w, h] = size;
  const ratio = h / w;
  const parts = useCutlistState.getState().parts;
  const hasGrainDirection = useActiveGroupGrainDirection();
  const { hasEdgeProfiling } = useCurrentFeatures();

  return (
    <section
      className={cn(
        styles.nestingSheetWrapper,
        hasGrainDirection && styles.grainBackground
      )}
    >
      <article
        className={styles.nestingSheet}
        style={{ '--paddingB': `calc(${ratio} * 100%)` } as React.CSSProperties}
      >
        {sheetEdgeTrimConfig && (
          <>
            {sheetEdgeTrimConfig.trimThickness.width1TrimThicknessMM > 0 && (
              <div
                style={{
                  width: '0px',
                  height: 'calc(100% + 20px)',
                  top: '-10px',
                  left: '0px',
                  borderWidth: '0.5px',
                  borderColor: 'var(--gray-7)',
                }}
              />
            )}
            {sheetEdgeTrimConfig.trimThickness.length2TrimThicknessMM > 0 && (
              <div
                style={{
                  width: 'calc(100% + 20px)',
                  height: '0px',
                  top: '0px',
                  left: '-10px',
                  borderWidth: '0.5px',
                  borderColor: 'var(--gray-7)',
                }}
              />
            )}
            {sheetEdgeTrimConfig.trimThickness.width2TrimThicknessMM > 0 && (
              <div
                style={{
                  width: '0px',
                  height: 'calc(100% + 20px)',
                  top: '-10px',
                  left: 'calc(100%)',
                  borderWidth: '0.5px',
                  borderColor: 'var(--gray-7)',
                }}
              />
            )}
            {sheetEdgeTrimConfig.trimThickness.length1TrimThicknessMM > 0 && (
              <div
                style={{
                  width: 'calc(100% + 20px)',
                  height: '0px',
                  top: 'calc(100%)',
                  left: '-10px',
                  borderWidth: '0.5px',
                  borderColor: 'var(--gray-7)',
                }}
              />
            )}
          </>
        )}
        {sheet.parts.map((part, idx) => {
          const fullP = parts.find((p) => p.id === part.id);

          const edges = {
            length1: false,
            length2: false,
            width1: false,
            width2: false,
          };

          const sides: (keyof Edgebanding)[] | (keyof EdgeProfile)[] = [
            'length1',
            'length2',
            'width1',
            'width2',
          ];

          sides.map((side) => {
            const edge = hasEdgeProfiling
              ? fullP?.edgeProfile?.[side]
              : fullP?.edgebanding?.[side];

            if (edge) {
              edges[side] = true;
            }

            if (edge === 'none' || !edge) {
              edges[side] = false;
            }
          });

          const width = (part.lengthMM * 100) / w;
          const height = (part.widthMM * 100) / h;

          const left = (part.offsetX * 100) / w;
          const top = (part.offsetY * 100) / h;

          const sizeLabel = [part.lengthMM, part.widthMM].join('x');
          const tooltip = [part.name, sizeLabel].filter(Boolean).join(', ');

          const topBorder =
            part.orientation === 'horizontal' ? edges?.length2 : edges?.width2;
          const bottomBorder =
            part.orientation === 'horizontal' ? edges?.length1 : edges?.width1;
          const leftBorder =
            part.orientation === 'horizontal' ? edges?.width1 : edges?.length2;
          const rightBorder =
            part.orientation === 'horizontal' ? edges?.width2 : edges?.length1;

          const onWidth = '3px';
          const offWidth = '1px';

          const topBorderWidth = topBorder ? onWidth : offWidth;
          const bottomBorderWidth = bottomBorder ? onWidth : offWidth;
          const leftBorderWidth = leftBorder ? onWidth : offWidth;
          const rightBorderWidth = rightBorder ? onWidth : offWidth;

          const topBorderColor = topBorder ? 'var(--primary)' : 'var(--gray-8)';
          const bottomBorderColor = bottomBorder
            ? 'var(--primary)'
            : 'var(--gray-8)';
          const leftBorderColor = leftBorder
            ? 'var(--primary)'
            : 'var(--gray-8)';
          const rightBorderColor = rightBorder
            ? 'var(--primary)'
            : 'var(--gray-8)';

          return (
            <div
              key={idx}
              style={{
                width: `calc(${width}% - 1px)`, // give some space to ensure a gap between parts
                height: `calc(${height}% - 1px)`, // give some space to ensure a gap between parts
                top: `calc(${top}% + 0.5px)`,
                left: `calc(${left}% + 0.5px)`,
                borderWidth: `${topBorderWidth} ${rightBorderWidth} ${bottomBorderWidth} ${leftBorderWidth}`,
                borderColor: `${topBorderColor} ${rightBorderColor} ${bottomBorderColor} ${leftBorderColor}`,
              }}
              className="tooltip"
              data-tooltip={tooltip}
            >
              <div>{part.name}</div>
              <div>{sizeLabel}</div>
            </div>
          );
        })}
      </article>
    </section>
  );
};

let groupsRef;
export const useDebouncedSheetGroups = (): [SheetGroup[], string] => {
  const sheetGroups = useNestingStore((state) => state.sheetGroups);
  const debouncedGroups = useDebounce<SheetGroup[]>(sheetGroups, 500);

  groupsRef = JSON.stringify(debouncedGroups);

  const debouncedGroupsRef = useDebounce<string>(groupsRef, 1000);

  return [debouncedGroups, debouncedGroupsRef];
};

function usePricingFromNesting() {
  const pricing = usePricingStore();
  const { clientNumber } = useAuthStore();
  const saveSensitiveData = useSaveSensitiveData();
  const { mutateAsync } = useUpdateCutlist();
  const priceSensitiveData = useActiveGroupPricingSensitiveData();
  const { groups } = useMaterialGroupState();
  const store = useCutlistState();
  const isAgentLoggedIn = useAgentLoggedIn();
  const { id } = useParams();
  const userLead = useLeadDetails();
  const { mutateAsync: getPriceForMaterialGroups } =
    useGetPriceForMaterialGroups(id!);
  const [debouncedGroups, debouncedGroupsRef] = useDebouncedSheetGroups();

  React.useEffect(() => {
    if (!store.orderId || !id) return;

    if (!debouncedGroups.length) {
      pricing.setError(null);
      pricing.setAmountVat(0);
      pricing.setAmountNoVat(0);
      pricing.setPricing([]);
      pricing.toggleManualQuote(false);

      return;
    }

    const cutlistGroups = createBackendGrouping(debouncedGroups, groups);

    if (isAgentLoggedIn && !userLead.email) {
      return;
    }

    if (isAgentLoggedIn && userLead.email) {
      getPriceForMaterialGroups({
        ...priceSensitiveData,
        id,
        materialGroups: cutlistGroups,
      })
        .then((data) => {
          pricing.setError(null);
          pricing.setAmountVat(data.totalAmountInclVAT);
          pricing.setAmountNoVat(data.totalAmountExclVAT);
          pricing.setPricing(data.pricingLineItems);
          pricing.toggleManualQuote(data.manualQuote);
        })
        .catch((e) => {
          pricing.setError(e);
        });
      return;
    }

    if (!isInIframe()) {
      mutateAsync({})
        .then((data) => {
          pricing.setError(null);
          pricing.setAmountVat(data.totalAmountInclVAT);
          pricing.setAmountNoVat(data.totalAmountExclVAT);
          pricing.setPricing(data.pricingLineItems);
          pricing.toggleManualQuote(data.manualQuote);
        })
        .catch((e) => {
          pricing.setError(e);
        });
      return;
    }

    api
      .pricing(cutlistGroups, clientNumber || '')
      .then((data) => {
        pricing.setError(null);
        pricing.setAmountVat(data.totalAmountInclVAT);
        pricing.setAmountNoVat(data.totalAmountExclVAT);
        pricing.setPricing(data.pricingLineItems);
        pricing.toggleManualQuote(data.manualQuote);
      })
      .catch((e) => {
        pricing.setError(e);
      });
  }, [
    debouncedGroupsRef,
    saveSensitiveData,
    priceSensitiveData,
    userLead.email,
  ]);
}
