import { MaterialGroup } from '@cutr/constants/cutlist';
import { NestingOutput } from '@cutr/constants/cutlist-nesting';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { NestingGroup } from '@/utils/nesting';

export type SheetGroup = NestingOutput & NestingGroup;
export type NestingStore = {
  sheetGroups: SheetGroup[];
  setNesting(sheetGroups: SheetGroup[]): void;
};

export const useNestingStore = create<NestingStore>()(
  devtools((set) => ({
    sheetGroups: [],
    setNesting: (sheetGroups) => set(() => ({ sheetGroups })),
  }))
);

const NestingStatus = {
  RUNNING: 'running',
  COMPLETED: 'completed',
  ABORTED: 'aborted',
} as const;

type NestingStatus = (typeof NestingStatus)[keyof typeof NestingStatus];

export type NestingStatusStore = {
  status: Record<MaterialGroup['id'], NestingStatus>;
  setStatus(id: MaterialGroup['id'], status: NestingStatus): void;
  resetStatus(): void;
};

export const useNestingStatusStore = create<NestingStatusStore>()(
  devtools((set) => ({
    status: {},
    setStatus: (id, status) =>
      set((state) => ({ status: { ...state.status, [id]: status } })),
    resetStatus: () => set(() => ({ status: {} })),
  }))
);
